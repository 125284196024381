<div class="grid grid-cols-12 gap-x-6">
    @if (submissionPreviewNode.label) {
        <div
            class="col-span-12"
            [ngClass]="{
                'sm:col-span-6': submissionPreviewNode.value !== undefined,
                'font-semibold': submissionPreviewNode.pushValueLeft !== true
            }">
            <div
                [innerHTML]="submissionPreviewNode.label"
                class="px-2 pt-2 overflow-hidden sm:py-2 text-ellipsis"></div>
        </div>
    }
    @if (submissionPreviewNode.value !== undefined) {
        <div
            class="col-span-12 sm:col-span-6"
            [ngClass]="{
                'italic': !submissionPreviewNode.value
            }">
            <div class="px-2 pb-2 break-words sm:py-2">
                @if (submissionPreviewNode.linkValue) {
                    @for (item of submissionPreviewNode.linkValue; track $index) {
                        <div>
                            @if (item.src) {
                                <big-ui-button
                                    [buttonStyle]="'link'"
                                    [type]="'button'"
                                    (click)="showModal(item.src, item.label)">
                                    {{ item.label }}
                                </big-ui-button>
                            } @else {
                                <span>{{ item.label }}</span>
                            }
                        </div>
                    }
                } @else {
                    @if (submissionPreviewNode.value | isArray) {
                        @for (item of submissionPreviewNode.value; track $index) {
                            <div>
                                {{ item || stringReplaceService.get('components.preview.valueNotSpecified') }}
                            </div>
                        }
                    } @else {
                        {{ submissionPreviewNode.value || stringReplaceService.get('components.preview.valueNotSpecified') }}
                    }
                }
            </div>
        </div>
    }
</div>
