import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UiButtonStyle, UiButtonStyleDirective } from '@big-direkt/ui/directives';
@Component({
    selector: 'big-ui-button',
    standalone: true,
    imports: [NgClass, UiButtonStyleDirective],
    templateUrl: './button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    @Input() public id?: string;
    @Input() public buttonStyle?: UiButtonStyle;
    @Input() public classList = [''];
    @Input() public ariaLabel?: string;
    @Input() public type?: string;
    @Input() public tabindex?: number;
    @Input() public disabled?: boolean;

    @Output() public readonly clicked = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent): void {
        this.clicked.emit(event);
    }
}
