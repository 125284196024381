<button
    [bigUiButtonStyle]="buttonStyle"
    [classList]="classList"
    [attr.aria-label]="ariaLabel"
    [attr.id]="id"
    [attr.type]="type"
    [attr.tabindex]="tabindex"
    [disabled]="disabled"
    (click)="onClick($event)">
    <ng-content />
</button>
