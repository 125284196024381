import { Component } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { InputBaseComponent } from '../../../base-components/input-base/input-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    providers: [provideTranslationScope('ftbPrivacyPolicy', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class PrivacyPolicyComponent extends InputBaseComponent {
    public override classList = 'block';
    public errorOverrides = { required: 'ftbPrivacyPolicy.required' };

    public static register(): ComponentMap {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            big_webform_privacy_policy: PrivacyPolicyComponent,
        };
    }
}
