<div
    *transloco="let t; prefix: 'uiArc'"
    class="relative">
    <svg
        viewBox="0 0 240 166"
        class="w-[240px] h-[166px]">
        <defs>
            <linearGradient
                id="gradient"
                x1="0"
                y1="166"
                x2="240"
                y2="166"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#014785" />
                <stop
                    offset="1"
                    stop-color="#6980A7" />
            </linearGradient>
        </defs>
        <path
            #backgroundArc
            class="background-arc fill-none stroke-gray-400 stroke-[14px] [stroke-linecap:round]"
            id="fullArc" />

        <path
            #progressArc
            stroke="url(#gradient)"
            class="progress-arc fill-none stroke-[14px] [stroke-linecap:round]"
            id="progressArc" />
    </svg>
    <div class="absolute w-4/5 text-center transform -translate-x-1/2 bottom-1 left-1/2">
        <div class="font-bold text-primary font-ci leading-[56px]">
            <span class="text-[56px]">{{ current() }}</span>
            {{ t('outOf', { value: total() }) }}
        </div>
        <div>
            <strong>{{ label() }}</strong>
        </div>
    </div>
</div>
