/* eslint-disable @angular-eslint/template/cyclomatic-complexity, @angular-eslint/template/no-call-expression */
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { UiUserDataComponent } from '@big-direkt/ui/user-data';
import { FeatureFlagsService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoModule } from '@jsverse/transloco';
import { WizardBaseComponent } from '../../../base-components/wizard-base/wizard-base.component';
import { ReplaceTokenRefPipe } from '../../../pipes/replace-token-ref/replace-token-ref.pipe';
import { WizardFormProgress } from '../../../pipes/wizard-form-progress/wizard-form-progress.pipe';
import { WizardVisiblePipe } from '../../../pipes/wizard-visible/wizard-visible.pipe';
import { PageHierarchyFormNode } from '../../../services/page-navigation/page-hierarchy-node';
import { PageNavigationService } from '../../../services/page-navigation/page-navigation.service';
import { WizardStepItemComponent } from '../wizard-step-item/wizard-step-item.component';

@Component({
    selector: 'big-form-wizard',
    templateUrl: './wizard.component.html',
    providers: [provideTranslationScope('ftbWizard', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    imports: [
        NgClass,
        NgTemplateOutlet,
        ReplaceTokenRefPipe,
        UiUserDataComponent,
        TranslocoModule,
        WizardBaseComponent,
        WizardFormProgress,
        WizardStepItemComponent,
        WizardVisiblePipe,
    ],
    standalone: true,
})
export class WizardComponent extends WizardBaseComponent {
    public override readonly pageNavigationService = inject(PageNavigationService);
    private readonly mobileAppRepository = inject(MobileAppRepository);
    public readonly featureFlagsService = inject(FeatureFlagsService);

    public get mobileWizardLabel(): string | undefined {
        if (!this.currentPage?.parent) {
            return undefined;
        }

        if (this.currentPage.level === 1) {
            return `${this.pages.indexOf(this.currentPage) + 1}. ${this.currentPage.wizardLabel ?? ''}`;
        }

        // eslint-disable-next-line max-len
        return `${this.pages.indexOf(this.currentPage.parent) + 1}.${this.currentPage.parent.children.indexOf(this.currentPage) + 1} ${
            this.currentPage.wizardLabel ?? ''
        }`;
    }

    public get isLastPageActive(): boolean {
        return this.currentPage?.settings.name === 'end_page';
    }

    public goToPage(page: PageHierarchyFormNode): void {
        // REFACTOR: Same scrolling behaviour happens in FormViewComponent. Maybe we can move the scrolling into PageNavigationService?
        const scrollTarget = this.mobileAppRepository.isEmbeddedInMobileApp() ? 'body' : 'big-form-holder';
        this.scrollService.scroll(scrollTarget);

        this.pageNavigationService.gotoPage(page);
    }
}
