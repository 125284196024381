@if (isLamapoll) {
    <div [id]="lamapollId"></div>
} @else {
    <iframe
        #iframe
        class="w-full mt-0 mb-12"
        [ngStyle]="{ 'height.px': height }"
        [src]="data.url | sanitize: sanitizeContext"
        (load)="onIframeLoaded()"></iframe>
}
