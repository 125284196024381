import { NgModule } from '@angular/core';
import { ResourceTypes } from './models/resource.types';
import { RESOURCE_PATH_TOKEN } from './resource-path';
import { MapperInjectorService } from './response-mapper/mapper-injector.service';
import { EpqMapper } from './response-mapper/resource/epq.mapper';
import { RouterTranslatePathMapper } from './response-mapper/resource/router-translate-path.mapper';
import { SickNotesMapper } from './response-mapper/resource/sick-notes.mapper';
import { ResponseMapperService } from './response-mapper/response-mapper.service';

@NgModule({
    imports: [],
    providers: [
        MapperInjectorService,
        ResponseMapperService,
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.RouterTranslatePath]: '/router/translate-path?path=' }, multi: true },

        /** TODO Refactore to module approach like UserResponseMapper */
        { provide: MapperInjectorService.tokens[EpqMapper.type], useClass: EpqMapper },
        { provide: MapperInjectorService.tokens[RouterTranslatePathMapper.type], useClass: RouterTranslatePathMapper },
        { provide: MapperInjectorService.tokens[SickNotesMapper.type], useClass: SickNotesMapper },
    ],
})
export class RestApiClientModule {
}
