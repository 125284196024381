import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgressBarColorMap } from '../../models/progress-bar-color-map';

export const DEFAULT_COLOR_MAP: ProgressBarColorMap = {
    warningSpan: { min: 0, max: 350 },
    successSpan: { min: 350, max: 360 },
};
export const DEFAULT_SIZE_PX = 76;
const DEFAULT_SVG_RADIUS = 50;
const DEFAULT_SVG_BORDER_WIDTH = 4;
const HUNDRED_PERCENT = 100;

@Component({
    selector: 'big-ui-chart-circular-progress-bar',
    templateUrl: './circular-progress-bar.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgStyle],
})
export class CircularProgressBarComponent {
    @Input() public angle = 0;
    @Input() public size = DEFAULT_SIZE_PX;
    @Input() public colorMap: ProgressBarColorMap = DEFAULT_COLOR_MAP;

    public get svgRadius(): number {
        const half = 2;

        return DEFAULT_SVG_RADIUS - (((HUNDRED_PERCENT / this.size) * DEFAULT_SVG_BORDER_WIDTH) / half);
    }

    public get svgStrokeWidth(): number {
        return (HUNDRED_PERCENT / this.size) * DEFAULT_SVG_BORDER_WIDTH;
    }

    public get svgStrokeDashoffset(): number {
        const threeSixtyDegrees = 360;

        return HUNDRED_PERCENT - ((HUNDRED_PERCENT / threeSixtyDegrees) * this.angle);
    }
}
