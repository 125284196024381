import { DRUPAL_WEBFORM_TYPE_CONFIG, type FormConfigElement } from '@big-direkt/form/contracts';

export const findNonPersistentElements = (element: FormConfigElement | undefined): FormConfigElement[] => {
    if (!element) {
        return [];
    }

    if (DRUPAL_WEBFORM_TYPE_CONFIG[element.type].preventValuePersistence) {
        return [element];
    }

    return element.children.flatMap(child => findNonPersistentElements(child));
};
