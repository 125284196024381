@let isOpenState = isOpen();

<button
    *transloco="let t; prefix: 'uiInfoButton'"
    (click)="toggleTooltip($event)"
    type="button"
    [attr.aria-expanded]="isOpenState"
    [attr.aria-controls]="scrollAnchorId"
    [title]="!isOpenState ? t('label.showInfo') : t('label.hideInfo')"
    class="overflow-hidden">
    <span class="sr-only">{{ !isOpenState ? t('label.showInfo') : t('label.hideInfo') }}</span>
    <big-icon
        [size]="'w-6'"
        [icon]="!isOpenState ? iconInfo : iconClose"
        class="fill-primary" />
</button>
