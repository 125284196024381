<ng-template appElementHost />
@if (settings.multipleAddMore || settings.multipleOperations) {
    @if (control?.enabled) {
        @if (isLast && settings.multipleAddMore) {
            <big-ui-button
                [buttonStyle]="'primary'"
                (clicked)="doAdd()"
                [type]="'button'"
                class="mr-2">
                {{ settings.multipleAddMoreButtonLabel }}
            </big-ui-button>
        }
        @if (isLast && !isFirst && settings.multipleOperations) {
            <big-ui-button
                (clicked)="doDelete()"
                [type]="'button'"
                [buttonStyle]="'primary'">
                {{ 'components.multiple.removeItem' | stringReplacePipe }}
            </big-ui-button>
        }
    }
}
