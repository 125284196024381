import { AsyncPipe, KeyValuePipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';

import { ENVIRONMENT_INITIALIZER, NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { ErrorHandlingAlertComponent } from '@big-direkt/error-handling';
import { ParagraphsHolderModule } from '@big-direkt/paragraphs-holder';
import { SearchResultTrackingDirective } from '@big-direkt/search';
import { UiAccordionComponent, UiAccordionItemComponent } from '@big-direkt/ui/accordion';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiCheckboxComponent } from '@big-direkt/ui/checkbox';
import { UiButtonStyleDirective } from '@big-direkt/ui/directives';
import { UiErrorComponent } from '@big-direkt/ui/error';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiIframeComponent } from '@big-direkt/ui/iframe';
import { UiInfoButtonComponent } from '@big-direkt/ui/info-button';
import { UiAutocompleteInputComponent, UiCurrencyInputComponent, UiInputComponent, UiOneTimeTokenComponent } from '@big-direkt/ui/input';
import { UiLabelComponent } from '@big-direkt/ui/label';
import { UiLinkModule } from '@big-direkt/ui/link';
import { UiLinkListItemComponent } from '@big-direkt/ui/link-list';
import { UiProgressBarComponent } from '@big-direkt/ui/progress-bar';
import { UiRadiosComponent } from '@big-direkt/ui/radios';
import { UiSelectComponent } from '@big-direkt/ui/select';
import { UiSpinnerModule } from '@big-direkt/ui/spinner';
import { UiUserDataComponent } from '@big-direkt/ui/user-data';
import { IconComponent } from '@big-direkt/utils/icons';
import { DropzoneDirective, IsInViewportDirective, Nl2brPipe, SanitizePipe, TrimInputOnBlurDirective } from '@big-direkt/utils/shared';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { BaseComponent } from './base-components/base/base.component';
import { InputBaseComponent } from './base-components/input-base/input-base.component';
import { NestedValuesBaseComponent } from './base-components/nested-value-base/nested-values-base.component';
import { SelectBaseComponent } from './base-components/select-base/select-base.component';
import { StructureBaseComponent } from './base-components/structure-base/structure-base.component';
import { TextfieldBaseComponent } from './base-components/textfield-base/textfield-base.component';
import { BaseAttributesDirective } from './directives/base-attributes/base-attributes.directive';
import { ElementHostDirective } from './directives/element-host/element-host.directive';
import { FieldTrackingDirective } from './directives/field-tracking/field-tracking.directive';
import { FormSearchTrackingDirective } from './directives/form-search-tracking/form-search-tracking.directive';
import { AddressComponent } from './fields/components/address/address.component';
import { AlertComponent } from './fields/components/alert/alert.component';
import { CheckboxComponent } from './fields/components/checkbox/checkbox.component';
import { CheckboxesComponent } from './fields/components/checkboxes/checkboxes.component';
import { ContactFormSearchComponent } from './fields/components/contact-form-search/contact-form-search.component';
import { CurrencyInputComponent } from './fields/components/currency-input/currency-input.component';
import { DateRangeComponent } from './fields/components/date-range/date-range.component';
import { DateComponent } from './fields/components/date/date.component';
import { DatelistComponent } from './fields/components/datelist/datelist.component';
import { DateTimeComponent } from './fields/components/datetime/datetime.component';
import { FileUploadComponent } from './fields/components/file-upload/file-upload.component';
import { HiddenComponent } from './fields/components/hidden/hidden.component';
import { HinComponent } from './fields/components/hin/hin.component';
import { IframeComponent } from './fields/components/iframe/iframe.component';
import { MarkupComponent } from './fields/components/markup/markup.component';
import { MobileAppInfoComponent } from './fields/components/mobile-app-info/mobile-app-info.component';
import { NavigationButtonComponent } from './fields/components/navigation-button/navigation-button.component';
import { NumberfieldComponent } from './fields/components/numberfield/numberfield.component';
import { OneTimeTokenComponent } from './fields/components/one-time-token/one-time-token.component';
import { PasswordConfirmComponent } from './fields/components/password-confirm/password-confirm.component';
import { PasswordComponent } from './fields/components/password/password.component';
import { PrivacyPolicyComponent } from './fields/components/privacy-policy/privacy-policy.component';
import { QuantitySelectComponent } from './fields/components/quantity-select/quantity-select.component';
import { RadioComponent } from './fields/components/radio/radio.component';
import { RadiosComponent } from './fields/components/radios/radios.component';
import { SearchableSelectComponent } from './fields/components/searchable-select/searchable-select.component';
import { SelectComponent } from './fields/components/select/select.component';
import { TextareaComponent } from './fields/components/textarea/textarea.component';
import { TextfieldComponent } from './fields/components/textfield/textfield.component';
import { TimeRangeComponent } from './fields/components/time-range/time-range.component';
import { TimeComponent } from './fields/components/time/time.component';
import { checkboxesValidatorFactory } from './fields/factories/checkboxes-validator.factory';
import { currencyFieldValidatorFactory } from './fields/factories/currency-field-validator.factory';
import { hinFieldValidatorFactory } from './fields/factories/hin-field-validator.factory';
import { numberFieldValidatorFactory } from './fields/factories/number-field-validator.factory';
import { passwordConfirmValidatorFactory } from './fields/factories/password-confirm-validator.factory';
import { textFieldValidatorFactory } from './fields/factories/text-field-validator.factory';
import { timeRangeChildValidatorFactory } from './fields/factories/time-range-child-validator.factory';
import { FormViewComponent } from './form-view.component';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN } from './interfaces/custom-form-control-validator.token';
import { ReplaceTokenRefPipe } from './pipes/replace-token-ref/replace-token-ref.pipe';
import { StringReplacePipe } from './pipes/string-replace/string-replace.pipe';
import { PreviewModule } from './preview/preview.module';
import { DrupalWebformService } from './services/drupal-webform/drupal-webform.service';
import { StatePreviewComponent } from './state-preview/state-preview.component';
import { CompositeComponent } from './structures/components/composite/composite.component';
import { ContainerComponent } from './structures/components/container/container.component';
import { ElementsHolderComponent } from './structures/components/elements-holder/elements-holder.component';
import { EmbeddedFormComponent } from './structures/components/embedded-form/embedded-form.component';
import { FaqComponent } from './structures/components/faq/faq.component';
import { FieldGroupComponent } from './structures/components/field-group/field-group.component';
import { FieldsetComponent } from './structures/components/fieldset/fieldset.component';
import { FormRelationComponent } from './structures/components/form-relation/form-relation.component';
import { FormComponent } from './structures/components/form/form.component';
import { InitialLoadingComponent } from './structures/components/initial-loading/initial-loading.component';
import { ItemCollectionComponent } from './structures/components/item-collection/item-collection.component';
import { ItemComponent } from './structures/components/item/item.component';
import { LabelComponent } from './structures/components/label/label.component';
import { MultipleItemComponent } from './structures/components/multiple-item/multiple-item.component';
import { MultipleComponent } from './structures/components/multiple/multiple.component';
import { PageComponent } from './structures/components/page/page.component';
import { PdfDownloadComponent } from './structures/components/pdf-download/pdf-download.component';
import { ProgressBarComponent } from './structures/components/progress-bar/progress-bar.component';
import { WizardComponent } from './structures/components/wizard/wizard.component';

const directivesToDeclare = [ElementHostDirective];
const componentsToDeclare = [
    AddressComponent,
    AlertComponent,
    BaseComponent,
    CheckboxComponent,
    CheckboxesComponent,
    ContactFormSearchComponent,
    CurrencyInputComponent,
    CompositeComponent,
    ContainerComponent,
    DatelistComponent,
    DateTimeComponent,
    ElementsHolderComponent,
    EmbeddedFormComponent,
    FaqComponent,
    FieldGroupComponent,
    FieldsetComponent,
    FileUploadComponent,
    FormComponent,
    FormRelationComponent,
    FormViewComponent,
    HiddenComponent,
    HinComponent,
    IframeComponent,
    InitialLoadingComponent,
    InputBaseComponent,
    ItemCollectionComponent,
    ItemComponent,
    LabelComponent,
    MarkupComponent,
    MultipleComponent,
    MultipleItemComponent,
    NestedValuesBaseComponent,
    NumberfieldComponent,
    OneTimeTokenComponent,
    PageComponent,
    PasswordComponent,
    PasswordConfirmComponent,
    PdfDownloadComponent,
    PrivacyPolicyComponent,
    ProgressBarComponent,
    QuantitySelectComponent,
    RadioComponent,
    RadiosComponent,
    SearchableSelectComponent,
    SelectBaseComponent,
    SelectComponent,
    StructureBaseComponent,
    TextareaComponent,
    TextfieldBaseComponent,
    TextfieldComponent,
    TimeComponent,
    TimeRangeComponent,
];

const componentsToRegister = [...componentsToDeclare, DateComponent, DateRangeComponent, MobileAppInfoComponent, NavigationButtonComponent];

@NgModule({
    declarations: [...directivesToDeclare, ...componentsToDeclare],
    exports: [FormViewComponent],
    imports: [
        AsyncPipe,
        BaseAttributesDirective,
        DropzoneDirective,
        ErrorHandlingAlertComponent,
        FieldTrackingDirective,
        FormSearchTrackingDirective,
        FormsModule,
        IconComponent,
        IsInViewportDirective,
        KeyValuePipe,
        MatAutocompleteModule,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        NgxMaskDirective,
        Nl2brPipe,
        ParagraphsHolderModule,
        PreviewModule,
        ReactiveFormsModule,
        ReplaceTokenRefPipe,
        RouterModule,
        SanitizePipe,
        SearchResultTrackingDirective,
        StatePreviewComponent,
        StringReplacePipe,
        TranslocoModule,
        TrimInputOnBlurDirective,
        UiAccordionComponent,
        UiAccordionItemComponent,
        UiAlertComponent,
        UiAutocompleteInputComponent,
        UiButtonComponent,
        UiButtonStyleDirective,
        UiCheckboxComponent,
        UiCurrencyInputComponent,
        UiErrorComponent,
        UiFormRowComponent,
        UiIframeComponent,
        UiInfoButtonComponent,
        UiInputComponent,
        UiLabelComponent,
        UiLinkListItemComponent,
        UiLinkModule,
        UiOneTimeTokenComponent,
        UiProgressBarComponent,
        UiRadiosComponent,
        UiSelectComponent,
        UiSpinnerModule,
        UiUserDataComponent,
        WizardComponent,
    ],
    providers: [
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: textFieldValidatorFactory,
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: numberFieldValidatorFactory,
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: currencyFieldValidatorFactory,
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: checkboxesValidatorFactory,
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: passwordConfirmValidatorFactory,
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: hinFieldValidatorFactory,
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: timeRangeChildValidatorFactory,
            multi: true,
        },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: (): void => {
                inject(DrupalWebformService).registerComponents(componentsToRegister);
            },
            multi: true,
        },
        provideNgxMask(),
    ],
})
export class FormViewModule {}
