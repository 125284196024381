import { Component } from '@angular/core';
import { StructureBaseComponent } from '../../../base-components/structure-base/structure-base.component';
import { type ComponentMap } from '../../../utilities/component-map/component-map';

@Component({
    selector: 'big-form-item-collection',
    templateUrl: './item-collection.component.html',
})
export class ItemCollectionComponent extends StructureBaseComponent {
    public override classList = 'grid grid-cols-12';

    public static register(): ComponentMap {
        return {
            webform_personal_data_element: ItemCollectionComponent, // eslint-disable-line @typescript-eslint/naming-convention
            webform_current_bank_data_element: ItemCollectionComponent, // eslint-disable-line @typescript-eslint/naming-convention
        };
    }
}
