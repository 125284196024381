import { Component, computed, input } from '@angular/core';
import { ProgressBarDirection, progressBarDirection } from './progress-bar-direction';

@Component({
    selector: 'big-ui-progress-bar',
    templateUrl: './progress-bar.component.html',
    standalone: true,
})
export class ProgressBarComponent {
    private readonly min = 0;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private readonly max = 100;

    public readonly border = input<boolean>(true);
    public readonly current = input<number>(0);
    public readonly direction = input<ProgressBarDirection>(progressBarDirection.down);
    public readonly fixedHeight = input<boolean>(true);
    public readonly hasError = input<boolean>(false);

    public progress = computed(() => {
        const current = this.min > this.current() ? this.min : this.current();

        if (this.hasError()) {
            return this.max;
        }

        return this.direction() === progressBarDirection.up
            ? this.reduceToMaxValue(current)
            : this.max - this.reduceToMaxValue(current);
    });

    private reduceToMaxValue(value: number): number {
        return value > this.max ? this.max : value;
    }
}
