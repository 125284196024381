/* eslint-disable @typescript-eslint/no-explicit-any */
export const getValuesByArrayParents = (arrayParents: string[], value: Record<string, any> | undefined): Record<number, any> | undefined => {
    let returnValue: Record<string, any> | undefined = value;

    arrayParents.forEach(parent => {
        returnValue = returnValue?.[parent];
    });

    return returnValue;
};
