import { Injectable, inject } from '@angular/core';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { type BigUntypedFormControl } from '../../form-control/big-form-control';
import { FormService } from '../form/form.service';
import { ConditionalStateCallbackService, type ConditionalStateCallbackType } from './conditional-state-callback';
import { ConditionalStateHandler } from './conditional-state-handler';
import { AndNode } from './state-tree/and-node';
import { type BooleanEvaluator } from './state-tree/boolean-evaluator';
import { ConditionNode } from './state-tree/condition-node';
import { OrNode } from './state-tree/or-node';
import { XorNode } from './state-tree/xor-node';

type ElementContext = 'big_webform_embedded_form' | 'big_webform_repeated_page' | 'form' | 'webform_multiple_item';

@Injectable({
    providedIn: 'root',
})
export class ConditionalStateParserService {
    private readonly formService = inject(FormService);
    private readonly callbacksService = inject(ConditionalStateCallbackService);
    private triggeringControls?: BigUntypedFormControl[];

    private registeredStateHandlers: Record<string, ConditionalStateHandler[] | undefined> = {};

    public buildStateHandler(affectedElement: FormConfigElement, stateType: ConditionalStateCallbackType): void {
        this.triggeringControls = [];

        const stateEvaluator: BooleanEvaluator = this.parseStateDefinition(affectedElement, affectedElement.states[stateType]);

        const handler: ConditionalStateHandler = new ConditionalStateHandler(
            affectedElement,
            this.triggeringControls,
            stateEvaluator,
            this.callbacksService.get(stateType),
            this.formService,
            this.registeredStateHandlers,
        );

        this.triggeringControls = undefined;
        this.registerHandler(handler, affectedElement.id);
    }

    public reset(): void {
        this.registeredStateHandlers = {};
    }

    private registerHandler(handler: ConditionalStateHandler, elementId: string): void {
        if (!this.registeredStateHandlers[elementId]) {
            this.registeredStateHandlers[elementId] = [];
        }

        this.registeredStateHandlers[elementId].push(handler);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private parseStateDefinition(context: FormConfigElement, stateDef: any): BooleanEvaluator {
        return Array.isArray(stateDef) ? this.parseStateDefinitionArray(context, stateDef) : this.parseStateDefinitionObject(context, stateDef);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private parseStateDefinitionArray(context: FormConfigElement, stateDef: any): BooleanEvaluator {
        let resultNode: BooleanEvaluator = this.parseStateDefinition(context, stateDef[0]);

        for (let i = 1; i < stateDef.length; i++) {
            let node: BooleanEvaluator;
            switch (stateDef[i]) {
                case 'or':
                    // eslint-disable-next-line sonarjs/updated-loop-counter
                    i = i + 1;
                    node = new OrNode(resultNode, this.parseStateDefinition(context, stateDef[i]));
                    break;
                case 'xor':
                    // eslint-disable-next-line sonarjs/updated-loop-counter
                    i = i + 1;
                    node = new XorNode(resultNode, this.parseStateDefinition(context, stateDef[i]));
                    break;
                case 'and':
                    // eslint-disable-next-line sonarjs/updated-loop-counter
                    i = i + 1;
                    node = new AndNode(resultNode, this.parseStateDefinition(context, stateDef[i]));
                    break;
                default:
                    node = new AndNode(resultNode, this.parseStateDefinition(context, stateDef[i]));
            }

            resultNode = node;
        }

        return resultNode;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private parseStateDefinitionObject(context: FormConfigElement, stateDef: any): BooleanEvaluator {
        const keys: string[] = Object.keys(stateDef);

        let resultNode: BooleanEvaluator = this.parseCondition(context, keys[0], stateDef[keys[0]]);

        for (let i = 1; i < keys.length; i++) {
            let node: BooleanEvaluator;
            switch (keys[i]) {
                case 'or':
                    node = new OrNode(resultNode, this.parseStateDefinition(context, stateDef[keys[i]]));
                    break;
                case 'xor':
                    node = new XorNode(resultNode, this.parseStateDefinition(context, stateDef[keys[i]]));
                    break;
                case 'and':
                    node = new AndNode(resultNode, this.parseStateDefinition(context, stateDef[keys[i]]));
                    break;
                default:
                    node = new AndNode(resultNode, this.parseCondition(context, keys[i], stateDef[keys[i]]));
            }

            resultNode = node;
        }

        return resultNode;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private parseCondition(affectedElement: FormConfigElement, conditionKey: string, conditionDef: any): ConditionNode {
        const match: RegExpMatchArray | null = /:input\[name="(.*?)"\]/.exec(conditionKey);

        if (!match?.[1]) {
            throw new Error(`Condition key '${conditionKey}' is malformed`);
        }

        const settings = this.findReferencedElement(affectedElement, match);

        if (!settings) {
            throw new Error(`Conditional component '${match[1]}' wasn't found`);
        }

        // triggering controls are always input controls. therefor cannot be groups
        const control = this.formService.getFormControl(settings.arrayParents) as BigUntypedFormControl;

        if (!this.triggeringControls) {
            this.triggeringControls = [];
        }

        if (!this.triggeringControls.includes(control)) {
            this.triggeringControls.push(control);
        }

        return ConditionNode.create(control, conditionDef);
    }

    private findReferencedElement(affectedElement: FormConfigElement, match: RegExpMatchArray): FormConfigElement | undefined {
        return (
            this.findElementRecursively(match[1], affectedElement) ??
            this.findReferencedElementInContext(affectedElement, match, 'webform_multiple_item') ??
            this.findReferencedElementInContext(affectedElement, match, 'big_webform_repeated_page') ??
            this.findReferencedElementInContext(affectedElement, match, 'big_webform_embedded_form') ??
            this.findReferencedElementInContext(affectedElement, match, 'form')
        );
    }

    private findReferencedElementInContext(
        affectedElement: FormConfigElement,
        match: RegExpMatchArray,
        context: ElementContext,
    ): FormConfigElement | undefined {
        const formSettings = this.getParentContext(affectedElement, context);

        return formSettings && this.findElementRecursively(match[1], formSettings);
    }

    private getParentContext(item: FormConfigElement, formType: ElementContext): FormConfigElement | undefined {
        const { parent } = item;
        if (!parent || parent.type === formType) {
            return parent;
        }

        return this.getParentContext(parent, formType);
    }

    private findElementRecursively(name: string, formElement: FormConfigElement): FormConfigElement | undefined {
        if (formElement.name === name) {
            return formElement;
        }

        for (const child of formElement.children) {
            // We do not search inside embedded forms
            if (child.type === 'big_webform_embedded_form') {
                continue;
            }

            const result = this.findElementRecursively(name, child);

            if (result) {
                return result;
            }
        }

        return undefined;
    }
}
