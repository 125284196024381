<fieldset class="mb-4 col-span-12">
    @if (settings.titleDisplay !== 'invisible') {
        <legend
            class="float-left w-full pl-0 h4">
            {{ label }}
        </legend>
    }

    <ng-template appElementHost />
</fieldset>
