<big-ui-alert
    [type]="settings.display"
    [title]="label"
    [classList]="'flex text-center border-2 rounded-md p-4 flex-column items-center'">
    @if (settings.markup) {
        <div
            [innerHTML]="settings.markup | replaceTokenRef: settings.arrayParents | sanitize: markupSecurity"
            class="text-primary-dark [&>*:last-child]:mb-0 [&_a]:text-primary"></div>
    }
    @if (settings.linkPath) {
        <big-ui-link
            [href]="settings.linkPath"
            [linkClass]="'flex font-semibold flex-grow-1 align-items-center text-decoration-none text-primary'">
            <div class="mr-1">{{ settings.linkTitle || settings.linkPath }}</div>
            <big-icon
                [icon]="iconArrowRight"
                [size]="'w-4'"
                [inline]="true"
                class="fill-current" />
        </big-ui-link>
    }
</big-ui-alert>
