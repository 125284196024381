/* eslint-disable @typescript-eslint/unbound-method */
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators, type ValidatorFn } from '@angular/forms';
import { EventBusService } from '@big-direkt/event-bus';
import { DRUPAL_WEBFORM_TYPE_CONFIG, type FormConfigElement } from '@big-direkt/form/contracts';
import { captureException } from '@sentry/browser';
import { BaseComponent } from '../../base-components/base/base.component';
import { type BigAnyFormControl } from '../../form-control/big-any-form-control';
import { BigUntypedFormControl } from '../../form-control/big-form-control';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN, type CustomFormControlValidatorEntry } from '../../interfaces/custom-form-control-validator.token';
import { markControlAsDirty } from '../../utilities/form-control-handler/form-control-handler';
import { FormGroupRequiredValidator } from '../../utilities/form-group-required-validator/form-group-required-validator';
import { DrupalWebformService } from '../drupal-webform/drupal-webform.service';
import { TokenService } from '../token/token.service';
import { BigUntypedFormGroup } from './../../form-control/big-form-group';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    private readonly eventBus = inject(EventBusService);
    private readonly drupalWebformService = inject(DrupalWebformService);
    private readonly tokenService = inject(TokenService);
    private readonly customFormControlValidatorToken = inject<CustomFormControlValidatorEntry[]>(CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN);
    private readonly destroyRef = inject(DestroyRef);
    public isPageValidationFailed!: boolean;
    public pdfDownloadClickDisabled = false;

    private formsControlsValueStateRegistrar: Record<string, Record<string, unknown> | undefined> = {};
    private currentFormGroup = new BigUntypedFormGroup({});

    public currentWebformId = '';
    public skipHandlerInitialization = true;

    public get currentForm(): BigUntypedFormGroup {
        return this.currentFormGroup;
    }

    public constructor() {
        this.eventBus
            .on('form_unloaded')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.currentWebformId = '';
                this.currentFormGroup = new BigUntypedFormGroup({});
            });
    }

    public getFormControl(arrayParents: string[]): BigAnyFormControl | undefined {
        return arrayParents.length ? (this.currentFormGroup.get(arrayParents) as BigAnyFormControl | undefined) : this.currentFormGroup;
    }

    public tryPersistFormValueState(webformId: string, nonPersistentElementPaths: string[][]): void {
        if (!this.formsControlsValueStateRegistrar[webformId]) {
            return;
        }

        const value = this.currentFormGroup.value;

        nonPersistentElementPaths.forEach(path => {
            let currentValue = value;
            for (let i = 0; i < path.length - 1; i++) {
                if (currentValue[path[i]] === undefined) {
                    return;
                }
                currentValue = currentValue[path[i]];
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete currentValue[path[path.length - 1]];
        });

        this.formsControlsValueStateRegistrar[webformId] = value;
    }

    public clearFormValueState(): void {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.formsControlsValueStateRegistrar[this.currentWebformId];
    }

    public getPersistedFormValue(): Record<string, unknown> | undefined {
        return this.formsControlsValueStateRegistrar[this.currentWebformId];
    }

    public initializeFormValueState(webformId: string): void {
        const value = this.formsControlsValueStateRegistrar[webformId];

        if (!value) {
            this.formsControlsValueStateRegistrar[webformId] = this.currentFormGroup.value;

            return;
        }

        this.currentFormGroup.patchValue(value);
        markControlAsDirty(this.currentFormGroup);
    }

    public createControls(settings: FormConfigElement): void {
        this.initializeControl(settings);
        this.tokenService.register(settings, this);
        if (DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].preventChildProcessing) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const componentClass = this.drupalWebformService.registeredComponentType(settings) as typeof BaseComponent | undefined;
            componentClass?.initChildControls(settings, this);

            return;
        }
        settings.children.forEach(c => {
            this.createControls(c);
        });
    }

    public initializeControl(settings: FormConfigElement): void {
        const control = this.createControlInParent(settings);
        if (control instanceof BigUntypedFormControl && settings.defaultValue) {
            control.setValue(settings.defaultValue);
        }
        control.isRequired = settings.required ?? false;
        this.setValidators(settings, control);
    }

    public removeControl(parentPath: string[], name: string): void {
        const parentGroup = this.currentFormGroup.get(parentPath);
        if (!parentGroup || !(parentGroup instanceof BigUntypedFormGroup)) {
            captureException(`tried to remove form control ${name} on path ${parentPath.join(',')}`);

            return;
        }
        parentGroup.removeControl(name);
    }

    public resetValue(settings: FormConfigElement): void {
        if (DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].isStructure) {
            settings.children.forEach(child => {
                this.resetValue(child);
            });

            return;
        }

        this.getFormControl(settings.arrayParents)?.reset(settings.defaultValue ?? '');
    }

    public getValidators(settings: FormConfigElement): ValidatorFn[] {
        const validators = [...this.drupalWebformService.registeredValidators(settings)];

        if (settings.required) {
            if (DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].isStructure) {
                validators.push(FormGroupRequiredValidator.validate);
            } else {
                const validatorKey = DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].requiredValidatorKey ?? 'required';
                validators.push(validatorKey === 'required' ? Validators.required : Validators.requiredTrue);
            }
        }

        if (settings.minlength) {
            validators.push(Validators.minLength(settings.minlength));
        }

        if (settings.maxlength) {
            validators.push(Validators.maxLength(settings.maxlength));
        }

        this.customFormControlValidatorToken.find(entry => entry.type === settings.type)?.handle(settings, validators);

        return validators;
    }

    private setValidators(settings: FormConfigElement, control: BigAnyFormControl): void {
        const validators = this.getValidators(settings);

        control.rawValidators = validators;
        control.setValidators(validators);
    }

    private createControlInParent(settings: FormConfigElement): BigAnyFormControl {
        if (settings.type === 'form') {
            return this.currentFormGroup;
        }

        const control: BigAnyFormControl = DRUPAL_WEBFORM_TYPE_CONFIG[settings.type].isStructure ? new BigUntypedFormGroup({}) : new BigUntypedFormControl('');

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!settings.arrayParents) {
            captureException(`There are no array parents set on FormConfigElement ${JSON.stringify(settings)}`);

            return this.currentFormGroup;
        }

        const parentFormGroup = this.getParentFormGroup(settings);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,sonarjs/different-types-comparison
        if (settings.name === undefined) {
            captureException(`There is no name value set on FormConfigElement ${JSON.stringify(settings)}`);

            return this.currentFormGroup;
        }

        parentFormGroup.addControl(settings.name, control);

        return control;
    }

    private getParentFormGroup(settings: FormConfigElement): BigUntypedFormGroup {
        return settings.arrayParents.length === 1
            ? this.currentFormGroup
            : (this.currentFormGroup.get(settings.arrayParents.slice(0, -1)) as BigUntypedFormGroup);
    }
}
