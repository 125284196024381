<ng-container *transloco="let t; prefix: 'ftbPasswordConfirm'">
    <p>{{ t('label') }}</p>
    <ul>
        <li>
            <span class="mr-2">{{ t('passwordConfirm.lowerCaseLetter') }}</span>
            @if (hasLowerLetter) {
                <big-icon
                    [icon]="iconOk"
                    [size]="'w-4'"
                    [inline]="true"
                    class="mt-1 fill-success" />
            }
        </li>
        <li>
            <span class="mr-2">{{ t('passwordConfirm.upperCaseLetter') }}</span>
            @if (hasUpperLetter) {
                <big-icon
                    [icon]="iconOk"
                    [size]="'w-4'"
                    [inline]="true"
                    class="mt-1 fill-success" />
            }
        </li>
        <li>
            <span class="mr-2">{{ t('passwordConfirm.number') }}</span>
            @if (hasNumber) {
                <big-icon
                    [icon]="iconOk"
                    [size]="'w-4'"
                    [inline]="true"
                    class="mt-1 fill-success" />
            }
        </li>
        <li>
            <span class="mr-2">{{ t('passwordConfirm.specialCharacter') }}</span>
            @if (hasSpecialChar) {
                <big-icon
                    [icon]="iconOk"
                    [size]="'w-4'"
                    [inline]="true"
                    class="mt-1 fill-success" />
            }
        </li>
        <li>
            <span class="mr-2">{{ t('passwordConfirm.allowedCharacterLength') }}</span>
            @if (hasCorrectLength) {
                <big-icon
                    [icon]="iconOk"
                    [size]="'w-4'"
                    [inline]="true"
                    class="mt-1 fill-success" />
            }
        </li>
        <li>
            <span class="mr-2">{{ t('passwordsDoMatch') }}</span>
            @if (matches) {
                <big-icon
                    [icon]="iconOk"
                    [size]="'w-4'"
                    [inline]="true"
                    class="mt-1 fill-success" />
            }
        </li>
    </ul>
</ng-container>
<ng-template appElementHost />
