@switch (render) {
    @case ('bullet') {
        <ul
            class="mb-0 grid gap-4 tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li
                    [innerHTML]="item.text | nl2br | sanitize: securityContext"
                    [ngClass]="[
                        'before:-translate-y-1/2',
                        'before:absolute',
                        'before:bg-body',
                        'before:content-[\'\']',
                        'before:h-1',
                        'before:left-0',
                        'before:rounded-full',
                        'before:top-[0.8rem]',
                        'before:w-1',
                        'pl-3',
                        'relative',
                        'text-break',
                    ]"></li>
            }
        </ul>
    }
    @case ('bullet-bold') {
        <ul
            class="mb-0 grid gap-4 tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li
                    [ngClass]="[
                        'before:-translate-y-1/2',
                        'before:absolute',
                        'before:bg-body',
                        'before:content-[\'\']',
                        'before:h-1',
                        'before:left-0',
                        'before:rounded-full',
                        'before:top-[0.8rem]',
                        'before:w-1',
                        'pl-3',
                        'relative',
                        'text-break',
                    ]">
                    @if (item.title) {
                        <strong
                            class="mr-1"
                            [innerHtml]="item.title + ':' | nl2br | sanitize: securityContext"></strong>
                    }
                    <span [innerHTML]="item.text | nl2br | sanitize: securityContext"></span>
                </li>
            }
        </ul>
    }
    @case ('bullet-bold-heading') {
        <ul
            class="mb-0 grid gap-4 tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li
                    [ngClass]="[
                        'before:-translate-y-1/2',
                        'before:absolute',
                        'before:bg-body',
                        'before:content-[\'\']',
                        'before:h-1',
                        'before:left-0',
                        'before:rounded-full',
                        'before:top-[0.8rem]',
                        'before:w-1',
                        'pl-3',
                        'relative',
                        'text-break',
                    ]">
                    @if (item.title) {
                        <strong class="block mr-1">
                            {{ item.title }}
                        </strong>
                    }
                    <span
                        class="block"
                        [innerHTML]="item.text | nl2br | sanitize: securityContext"></span>
                </li>
            }
        </ul>
    }
    @case ('checkmark') {
        <ul
            class="mb-0 grid gap-4 tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li class="text-break">
                    <span class="flex">
                        <span class="flex-grow-0 w-4 mr-2 translate-y-1">
                            <big-icon
                                [icon]="iconOk"
                                [size]="'w-4'"
                                class="fill-primary" />
                        </span>
                        <span [innerHTML]="item.text | nl2br | sanitize: securityContext"></span>
                    </span>
                </li>
            }
        </ul>
    }
    @case ('checkmark-heading') {
        <ul
            class="mb-0 grid gap-4 tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li class="text-break">
                    <div class="flex">
                        <span class="mr-2">
                            <big-icon
                                [icon]="iconOk"
                                [size]="'w-4'"
                                [inline]="true"
                                class="fill-primary" />
                        </span>
                        <span>
                            @if (item.title) {
                                <strong class="block mb-2 mr-1 text-primary h4 letter-spacing-0">
                                    {{ item.title }}
                                </strong>
                            }
                            <span
                                class="block"
                                [innerHTML]="item.text | nl2br | sanitize: securityContext"></span>
                            @if (item.link && item.link.href && item.link.title) {
                                <big-ui-link
                                    [linkClass]="'text-primary'"
                                    [href]="item.link.href"
                                    [target]="item.link.target">
                                    {{ item.link.title }}
                                </big-ui-link>
                            }
                        </span>
                    </div>
                </li>
            }
        </ul>
    }
    @case ('circle-checkmark-heading') {
        <ul
            class="mb-0 grid gap-4 tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li class="text-break">
                    <div class="flex">
                        <span class="mr-2 mt-0.5 w-4 translate-y-0.5">
                            <big-icon
                                [icon]="iconOkCircle"
                                [size]="'w-4'"
                                class="fill-primary" />
                        </span>
                        <span>
                            @if (item.title) {
                                <strong class="block mr-1 letter-spacing-0">
                                    {{ item.title }}
                                </strong>
                            }
                            <span
                                class="block"
                                [innerHTML]="item.text | nl2br | sanitize: securityContext"></span>
                        </span>
                    </div>
                </li>
            }
        </ul>
    }
    @case ('counter') {
        <ol
            class="mb-0 grid gap-y-6 gap-x-4 [counter-reset:index] tracking-[0.5px]"
            [class]="columnClass">
            @for (item of columns === 1 ? items : (items | listBreakpointSort: isMobile()); track $index) {
                <li
                    [ngClass]="[
                        'before:[counter-increment:index]',
                        'before:absolute',
                        'before:border-primary',
                        'before:border',
                        'before:content-[counter(index)]',
                        'before:flex',
                        'before:h-8',
                        'before:items-center',
                        'before:justify-center',
                        'before:left-0',
                        'before:lg:left-[32px]',
                        'before:rounded-full',
                        'before:text-primary',
                        'before:text-xl',
                        'before:w-8',
                        'lg:pl-[94px]',
                        'pl-[62px]',
                        'relative',
                        'text-break',
                    ]">
                    @if (item.title) {
                        <strong
                            class="mr-1"
                            [innerHtml]="item.title | nl2br | sanitize: securityContext"></strong>
                    }
                    <span [innerHTML]="item.text | nl2br | sanitize: securityContext"></span>
                    @if (item.link && item.link.href && item.link.title) {
                        <span class="block pt-2">
                            <big-ui-link
                                [linkClass]="'text-primary font-semibold text-decoration-none'"
                                [href]="item.link.href"
                                [target]="item.link.target">
                                {{ item.link.title }}
                                <big-icon
                                    [icon]="iconArrowRight"
                                    [size]="'w-4'"
                                    [inline]="true"
                                    class="ml-1 translate-y-0.5 fill-primary" />
                            </big-ui-link>
                        </span>
                    }
                </li>
            }
        </ol>
    }
}
