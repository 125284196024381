import { ChangeDetectionStrategy, Component, effect, ElementRef, HostBinding, input, viewChild } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';

const HALF_OF = 2;
const WIDTH_PX = 240;
const HEIGHT_PX = 166;
const STROKE_WIDTH_PX = 14;
const OFFSET_PX = STROKE_WIDTH_PX / HALF_OF;
const POSITION_OFFSET_PX = 10;
const RADIUS_Y = 125;

@Component({
    selector: 'big-ui-chart-arc-progress-bar',
    templateUrl: './arc-progress-bar.component.html',
    standalone: true,
    imports: [TranslocoDirective],
    providers: [provideTranslationScope('uiArc', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArcProgressBarComponent {
    private readonly backgroundArc = viewChild<ElementRef<SVGPathElement>>('backgroundArc');
    private readonly progressArc = viewChild<ElementRef<SVGPathElement>>('progressArc');

    @HostBinding('class') public classList = 'inline-block';

    public current = input<number>(0);
    public total = input<number>(0);
    public label = input<string>('');

    public constructor() {
        effect(() => {
            if (this.backgroundArc() && this.progressArc()) {
                this.drawArc();
            }
        });
    }

    private drawArc(): void {
        // Calculate the ellipse parameters considering the stroke width
        const rx = (WIDTH_PX - HALF_OF * OFFSET_PX) / HALF_OF; // =113
        const cx = WIDTH_PX / HALF_OF; // =120
        const cy = HEIGHT_PX - OFFSET_PX; // =159

        // Calculate the progress as a fraction (fraction = 0 means no progress, fraction = 1 means full progress)
        // If the current value is greater than the total value, the progress is considered full.
        const fraction = (this.current() > this.total() ? this.total() : this.current()) / this.total();

        // Calculate the angle (fraction=0 => angle=π, fraction=1 => angle=0)
        const angle = Math.PI * (1 - fraction);

        // Starting points that include the offset
        const xStart = cx - rx; // =7
        const yStart = cy - POSITION_OFFSET_PX; // =149

        // Calculate the end point of the arc based on the progress
        const xEnd = cx + rx * Math.cos(angle);
        const yEnd = cy - RADIUS_Y * Math.sin(angle) - POSITION_OFFSET_PX;

        // This line constructs an SVG path string for drawing an arc.
        // 'M' moves the pen to the starting point (xStart, yStart).
        // 'A' draws an elliptical arc with the following parameters:
        // - rx: x-axis radius of the arc
        // - RADIUS_Y: y-axis radius of the arc
        // - 0: x-axis rotation (not rotated)
        // - 0: large-arc-flag (0 means the arc is less than 180 degrees)
        // - 1: sweep-flag (1 means the arc is drawn in a positive-angle direction)
        // The arc ends at the point (cx + rx, cy - POSITION_OFFSET_PX).
        const backgroundArcPath = `M ${xStart} ${yStart} A ${rx} ${RADIUS_Y} 0 0 1 ${cx + rx} ${cy - POSITION_OFFSET_PX}`;

        // Constructing an SVG path for an arc using the 'M' (move to) and 'A' (arc to) commands.
        // 'M' moves the pen to the starting point (xStart, yStart).
        // 'A' draws an arc with the following parameters:
        // - rx: x-axis radius of the arc
        // - RADIUS_Y: y-axis radius of the arc
        // - 0: x-axis rotation (not rotated)
        // - 0: large-arc-flag (0 means the arc is less than 180 degrees)
        // - 1: sweep-flag (1 means the arc is drawn in a positive-angle direction)
        // - xEnd: x-coordinate of the end point of the arc
        // - yEnd: y-coordinate of the end point of the arc
        const progressArcPath = `M ${xStart} ${yStart} A ${rx} ${RADIUS_Y} 0 0 1 ${xEnd} ${yEnd}`;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.backgroundArc()!.nativeElement.setAttribute('d', backgroundArcPath);

        if (this.current() > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.progressArc()!.nativeElement.setAttribute('d', progressArcPath);
        }
    }
}
