<div
        class="w-full bg-gray-400 rounded-md overflow-hidden border-gray-300"
        [class.border-2]="border()"
        [class.h-7]="fixedHeight()"
>
    <div
            class="h-full transition-[width] text-white text-xs flex justify-center items-center"
            [class.bg-info]="!hasError()"
            [class.bg-danger]="hasError()"
            [style.width.%]="progress()">
        <ng-content />
    </div>
</div>
