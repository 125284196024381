<big-ui-form-row
    *transloco="let t; prefix: 'ftbPassword'"
    [id]="settings.id"
    [label]="label"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [errorOverrides]="errorOverrides"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <div class="relative">
        <input
            class="relative form-control"
            [id]="settings.id"
            [type]="showPassword ? 'text' : 'password'"
            [formControl]="control"
            [ngClass]="{
                'is-valid': isValid,
                'is-invalid': isInvalid,
            }" />

        <label
            for="check-{{ settings.id }}"
            class="absolute inline-block w-8 h-8 cursor-pointer right-6 top-3">
            <big-icon
                [size]="'w-8'"
                [icon]="!showPassword ? iconShow : iconHide"
                class="fill-primary" />
            <input
                type="checkbox"
                class="sr-only"
                id="check-{{ settings.id }}"
                tabindex="-1"
                [checked]="showPassword"
                (change)="showPassword = !showPassword" />
            <span class="sr-only">{{ !showPassword ? t('showPassword') : t('hidePassword') }}</span>
        </label>
    </div>
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
