@if (item?.pdfFileKey) {
    <div class="flex h-full border-b border-gray-300 letter-spacing-lg grow group">
        <button
            (click)="onDownloadLinkClick($event, item)"
            class="flex items-center py-4 text-left no-underline grow">
            <ng-template [ngTemplateOutlet]="content"></ng-template>
        </button>
    </div>
} @else {
    <big-ui-link
        class="flex h-full border-b border-gray-300 letter-spacing-lg grow group"
        [linkClass]="'flex grow items-center no-underline py-4'"
        [href]="item.href"
        [download]="item.isDownloadLink ? item.title : undefined"
        [ngClass]="{
            'grow': canGrow,
            'grow-0': !canGrow,
        }">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
    </big-ui-link>
}

<ng-template #content>
    @if (item?.icon) {
        <big-ui-svg-icon
            class="flex mr-4 grow-0 shrink-0 basis-5"
            [url]="item.icon" />
    }
    <div class="grow text-break">
        {{ parentCount === 1 ? (item.introduction ? item.introduction : item.title) : item.title }}
    </div>

    <div class="flex items-center h-full mx-4 text-right">
        @if (isLoading()) {
            <big-ui-spinner
                [size]="'s'"
                [spinnerClass]="'flex items-center justify-center'" />
        } @else {
            <big-icon
                [icon]="loadingFailed() ? iconDownloadFailed : item.isDownloadLink ? iconDownload : iconArrowRight"
                [size]="'w-4'"
                class=""
                [ngClass]="{
                    'fill-primary group-hover:fill-primary-light': !loadingFailed(),
                    'fill-danger': loadingFailed(),
                }" />
        }
    </div>
</ng-template>
